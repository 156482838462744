import React from 'react';

const IconSun = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 490.667 490.667"
    style={{ enableBackground: 'new 0 0 490.667 490.667' }}>
    <circle style={{ fill: '#FFC107' }} cx="245.333" cy="245.333" r="160" />
    <g>
      <path
        style={{ fill: '#FFD54F' }}
        d="M245.333,64C251.224,64,256,59.224,256,53.333V10.667C256,4.776,251.224,0,245.333,0
		c-5.891,0-10.667,4.776-10.667,10.667v42.667C234.667,59.224,239.442,64,245.333,64z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M245.333,426.667c-5.891,0-10.667,4.776-10.667,10.667V480c0,5.891,4.776,10.667,10.667,10.667
		c5.891,0,10.667-4.776,10.667-10.667v-42.667C256,431.442,251.224,426.667,245.333,426.667z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M480,234.667h-42.667c-5.891,0-10.667,4.776-10.667,10.667c0,5.891,4.776,10.667,10.667,10.667H480
		c5.891,0,10.667-4.776,10.667-10.667C490.667,239.442,485.891,234.667,480,234.667z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M64,245.333c0-5.891-4.776-10.667-10.667-10.667H10.667C4.776,234.667,0,239.442,0,245.333
		C0,251.224,4.776,256,10.667,256h42.667C59.224,256,64,251.224,64,245.333z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M140.096,84.395c1.909,3.307,5.44,5.341,9.259,5.333c1.873,0.007,3.715-0.486,5.333-1.429
		c5.102-2.946,6.849-9.469,3.904-14.571l-21.333-36.949c-2.979-5.082-9.514-6.787-14.596-3.808
		c-5.035,2.951-6.763,9.401-3.878,14.474L140.096,84.395z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M350.571,406.272c-2.98-5.082-9.515-6.786-14.597-3.806c-5.033,2.952-6.761,9.4-3.878,14.473
		l21.333,36.949c1.909,3.307,5.44,5.341,9.259,5.333c1.873,0.007,3.715-0.486,5.333-1.429c5.102-2.946,6.849-9.469,3.904-14.571
		L350.571,406.272z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M411.605,160c1.873,0.007,3.715-0.486,5.333-1.429l36.949-21.333
		c5.22-2.731,7.238-9.176,4.507-14.396c-2.731-5.22-9.176-7.238-14.396-4.507c-0.266,0.139-0.525,0.289-0.778,0.45l-36.949,21.333
		c-5.102,2.946-6.849,9.47-3.904,14.571c1.905,3.3,5.426,5.333,9.237,5.333V160z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M73.728,332.096l-36.949,21.333c-5.102,2.946-6.849,9.469-3.904,14.571
		c1.905,3.3,5.426,5.333,9.237,5.333c1.873,0.007,3.715-0.486,5.333-1.429l36.949-21.333c5.22-2.731,7.238-9.176,4.507-14.396
		c-2.731-5.22-9.176-7.238-14.396-4.507c-0.266,0.139-0.525,0.289-0.778,0.45L73.728,332.096z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M36.779,137.237l36.949,21.333c1.613,0.939,3.446,1.433,5.312,1.429
		c5.891,0,10.666-4.776,10.666-10.667c0-3.81-2.033-7.331-5.333-9.237l-36.949-21.333c-4.972-3.16-11.564-1.692-14.724,3.279
		s-1.692,11.564,3.279,14.724c0.253,0.161,0.512,0.311,0.778,0.45L36.779,137.237z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M453.888,353.429l-36.949-21.333c-4.972-3.16-11.564-1.692-14.724,3.279
		c-3.16,4.971-1.692,11.564,3.279,14.724c0.253,0.161,0.513,0.311,0.778,0.45l36.949,21.333c1.619,0.943,3.46,1.436,5.333,1.429
		c5.891,0,10.666-4.776,10.666-10.667c0-3.811-2.033-7.332-5.333-9.237V353.429z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M336,88.299c1.619,0.943,3.46,1.436,5.333,1.429c3.818,0.008,7.349-2.027,9.259-5.333l21.333-36.949
		c2.911-5.121,1.119-11.633-4.002-14.544c-5.073-2.883-11.521-1.156-14.473,3.878l-21.333,36.949
		C329.168,78.824,330.906,85.346,336,88.299z"
      />
      <path
        style={{ fill: '#FFD54F' }}
        d="M154.667,402.368c-5.102-2.945-11.625-1.198-14.571,3.904l-21.333,36.949
		c-2.945,5.102-1.198,11.625,3.904,14.571c1.619,0.943,3.46,1.436,5.333,1.429c3.818,0.008,7.35-2.027,9.259-5.333l21.333-36.949
		c2.94-5.105,1.186-11.627-3.919-14.567C154.671,402.37,154.669,402.369,154.667,402.368z"
      />
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default IconSun;
