import React from 'react';

const IconMoon = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512.001 512.001"
    style={{ enableBackground: 'new 0 0 512.001 512.001' }}>
    <path
      style={{ fill: '#FFCD00' }}
      d="M466.947,385.773c-3.831-6.399-11.114-9.89-18.5-8.872c-9.879,1.364-19.956,2.055-29.95,2.055
	c-77.006,0-149.383-40.99-188.882-106.973c-45.387-75.817-41.113-170.979,10.889-242.438c4.388-6.03,4.75-14.098,0.919-20.498
	c-3.83-6.399-11.115-9.892-18.5-8.872c-34.299,4.733-66.864,16.216-96.791,34.131C4.507,107.118-35.209,265.305,37.602,386.931
	c23.243,38.826,56.338,70.887,95.709,92.715c38.179,21.167,81.436,32.355,125.094,32.355c46.306,0,91.889-12.635,131.821-36.54
	c29.926-17.915,55.429-41.194,75.803-69.191C470.416,400.24,470.779,392.172,466.947,385.773z"
    />
    <path
      style={{ fill: '#FFDA44' }}
      d="M341.133,115.751l-14.935-4.544c-1.689-0.513-3.519-0.248-4.991,0.725l-13.023,8.61
	c-4.63,3.061-10.548-1.355-8.933-6.665l4.544-14.935c0.513-1.689,0.248-3.519-0.725-4.991l-8.61-13.023
	c-3.061-4.63,1.355-10.548,6.665-8.933l14.935,4.544c1.689,0.513,3.519,0.248,4.991-0.725l13.023-8.61
	c4.63-3.061,10.548,1.355,8.933,6.665l-4.544,14.935c-0.513,1.689-0.248,3.519,0.725,4.991l8.61,13.023
	C350.86,111.447,346.443,117.367,341.133,115.751z"
    />
    <path
      style={{ fill: '#CCA400' }}
      d="M465.793,233.145l1.905,5.29c0.598,1.66,1.906,2.968,3.566,3.566l5.29,1.905
	c5.221,1.88,5.221,9.265,0,11.146l-5.29,1.905c-1.66,0.598-2.968,1.906-3.566,3.566l-1.905,5.29c-1.88,5.221-9.265,5.221-11.146,0
	l-1.905-5.29c-0.598-1.66-1.906-2.968-3.566-3.566l-5.29-1.905c-5.221-1.88-5.221-9.265,0-11.146l5.29-1.905
	c1.66-0.598,2.968-1.906,3.566-3.566l1.905-5.29C456.528,227.924,463.913,227.924,465.793,233.145z"
    />
    <path
      style={{ fill: '#FFEB99' }}
      d="M503.363,34.518L503.363,34.518c0.598,1.66,1.906,2.968,3.566,3.566l0,0
	c5.221,1.88,5.221,9.265,0,11.146l0,0c-1.66,0.598-2.968,1.906-3.566,3.566l0,0c-1.88,5.221-9.265,5.221-11.146,0l0,0
	c-0.598-1.66-1.906-2.968-3.566-3.566l0,0c-5.221-1.88-5.221-9.265,0-11.146l0,0c1.66-0.598,2.968-1.906,3.566-3.566l0,0
	C494.098,29.297,501.483,29.297,503.363,34.518z"
    />
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default IconMoon;
