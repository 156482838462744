import React from 'react';

const IconMail = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style={{ enableBackground: 'new 0 0 512 512' }}>
    <g>
      <g>
        <path
          d="M452,68H60C26.916,68,0,94.916,0,128v256c0,33.084,26.916,60,60,60h392c33.084,0,60-26.916,60-60V128
                C512,94.916,485.084,68,452,68z M472,384c0,11.028-8.972,20-20,20H60c-11.028,0-20-8.972-20-20V128c0-11.028,8.972-20,20-20h392
                c11.028,0,20,8.972,20,20V384z"
        />
      </g>
    </g>
    <g>
      <g>
        <polygon points="468.604,92.937 256,251.074 43.396,92.937 19.522,125.032 256,300.926 492.478,125.032 		" />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

export default IconMail;
